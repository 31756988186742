import '../scss/main.scss'

var minimalBookDonation = 100

const content = document.getElementById('donate')
document.addEventListener('scroll', (e) => {
  var scrolled = document.scrollingElement.scrollTop
  var position = content.offsetTop

  if (scrolled + window.innerHeight > position + 600) {
    content.classList.add(
      'opened')
  } else {
    content.classList.remove(
      'opened')
  }
})

var modal = document.getElementById('modal')

function pay (amount, email, data = {}) {
  var widget = new window.cp.CloudPayments()
  var publicId = 'pk_1e95c4f26ab2c3f0e577e444e5424' // production
  // var publicId = 'pk_dddc7d236b5793f78c1ddf0d26c2a' // demo key

  widget.pay('auth',
    {
      publicId,
      description: 'Благотворительное пожертвование на уставные цели', // назначение
      amount,
      email,
      requireEmail: true,
      currency: 'RUB',
      skin: 'mini',
      data: {
        ...data,
        publicId
      }
    },
    {
      onSuccess: function (options) {
        console.log(amount)
      },
      onFail: function (reason, options) { // fail
        console.log('failed')
      },
      onComplete: function (paymentResult, options) {
        if (paymentResult.success) {
          window.gtag('event', 'purchase', {
            value: options.amount,
            currency: 'RUB'
          })
          window.fbq('track', 'Purchase', { currency: 'RUB', value: options.amount })
          modal.style.display = 'flex'
        }
      }
    }
  )
}

var buyButton = document.getElementById('buy-button')
var emailInput = document.getElementById('email')
var fundraisingResult = document.getElementById('fundraising-result')
var progressBar = document.getElementById('progress')
var emailWrapper = document.getElementById('email-wrapper')
var emailLabel = document.getElementById('email-label')
var customAmountInput = document.getElementById('custom-amount')

var amount = 200

function setCustomAmount () {
  if (parseInt(customAmountInput.value) >= minimalBookDonation) {
    amount = parseInt(customAmountInput.value)
    customAmountInput.classList.add('active')
  } else {
    document.getElementById('default-option').classList.add('active')
    customAmountInput.value = ''
    amount = 200
  }
}

function getResults () {
  window.fetch('https://api.svetdeti.org/v2/projects/comics-2020/results')
    .then(response => response.json())
    .then(data => {
      fundraisingResult.innerHTML = data.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₽'
      progressBar.style = `width: ${(parseInt(data.amount) / 300000) * 100}%`
    })
    .catch(err => {
      console.error(err)
    })
}

document.addEventListener('DOMContentLoaded', function () {
  getResults()

  var options = document.querySelectorAll('.donation-options__option')

  document.getElementById('scroll-button').addEventListener('click', function (e) {
    e.preventDefault()
    document.getElementById('donate-now').scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  })

  buyButton.addEventListener('click', function () {
    var email = emailInput.value
    if (email && /(^\w.*@\w+\.\w)/.test(email)) {
      emailWrapper.classList.remove('error')
      emailLabel.classList.remove('error')
      emailLabel.innerHTML = 'ваш email'
      pay(amount, email)
    } else {
      emailLabel.classList.add('error')
      emailWrapper.classList.add('error')
      emailLabel.innerHTML = 'ошибка в email'
    }
    setTimeout(function () {
      getResults()
    }, 3000)
  })
  emailInput.addEventListener('input', function () {
    emailWrapper.classList.remove('error')
    emailLabel.classList.remove('error')
    emailLabel.innerHTML = 'ваш email'
  })

  customAmountInput.addEventListener('focus', function () {
    customAmountInput.classList.remove('active')
    options.forEach(function (o) {
      o.classList.remove('active')
    })
  })
  customAmountInput.addEventListener('blur', function () {
    setCustomAmount()
  })

  options.forEach(function (option) {
    option.addEventListener('click', function (e) {
      options.forEach(function (o) {
        o.classList.remove('active')
      })
      customAmountInput.classList.remove('active')
      customAmountInput.value = ''
      option.classList.add('active')
      amount = parseInt(e.target.dataset.amount)
    })
  })
})
